import { Web3 } from "web3";

function IsValidEVMAddress(adr) {
    try {
        const web3 = new Web3()
        web3.utils.toChecksumAddress(adr)
        return true
    } catch (e) {
        return false
    }
}

export default IsValidEVMAddress;