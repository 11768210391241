import React from 'react'

const alertStyle = {
  backgroundColor: '#141414',
  color: '#FEFEFE',
  borderRadius: '10px',
  border: '1px solid #5A5A5A',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0 4px 4px 0 rgba(0,0,0,0.25)', /* offset-x | offset-y | blur-radius | spread-radius | color */
  // width: '300px',
  boxSizing: 'border-box',
  fontSize: '.9rem',
  position: 'relative'
}

const contentWrapperStyle = {
  padding: '16px',
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  columnGap: '16px'
}

const iconPlaceholderStyle = {
  display: 'flex',
  justifyContent: 'center',
}

const messageStyle = {
  flex: 3,
  textAlign: 'left',
  width: '100%'
}

const closeButtonStyle = {
  // minWidth: '50px',
  height: '100%',
  // position: 'absolute',
  // borderRadius: '0 10px 10px 0',
  cursor: 'pointer',
  // top: 0,
  // right: 0,
  // backgroundColor: '#141414',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}

const getIcon = type => {
  switch (type) {
    case 'error':
      return  (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M16.0049 2.66992C13.3675 2.66992 10.7893 3.45201 8.5964 4.91727C6.40347 6.38254 4.69429 8.46518 3.68499 10.9018C2.6757 13.3385 2.41162 16.0197 2.92616 18.6064C3.44069 21.1932 4.71073 23.5693 6.57566 25.4342C8.44059 27.2991 10.8167 28.5692 13.4034 29.0837C15.9901 29.5982 18.6714 29.3341 21.108 28.3249C23.5447 27.3156 25.6273 25.6064 27.0926 23.4134C28.5578 21.2205 29.3399 18.6423 29.3399 16.0049C29.3399 12.4683 27.935 9.07645 25.4342 6.57565C22.9334 4.07486 19.5416 2.66992 16.0049 2.66992ZM14.3492 9.33742C14.3492 8.89534 14.5248 8.47136 14.8374 8.15876C15.15 7.84616 15.574 7.67055 16.016 7.67055C16.4581 7.67055 16.8821 7.84616 17.1947 8.15876C17.5073 8.47136 17.6829 8.89534 17.6829 9.33742V16.9939C17.6829 17.2128 17.6398 17.4296 17.556 17.6318C17.4723 17.8341 17.3495 18.0178 17.1947 18.1726C17.0399 18.3274 16.8562 18.4502 16.6539 18.5339C16.4517 18.6177 16.2349 18.6608 16.016 18.6608C15.7971 18.6608 15.5804 18.6177 15.3782 18.5339C15.1759 18.4502 14.9922 18.3274 14.8374 18.1726C14.6826 18.0178 14.5598 17.8341 14.476 17.6318C14.3923 17.4296 14.3492 17.2128 14.3492 16.9939V9.33742ZM16.0049 24.3393C15.6269 24.3393 15.2574 24.2272 14.943 24.0172C14.6287 23.8071 14.3837 23.5086 14.2391 23.1594C14.0944 22.8101 14.0566 22.4258 14.1303 22.0551C14.2041 21.6843 14.3861 21.3437 14.6534 21.0764C14.9207 20.8091 15.2613 20.6271 15.632 20.5533C16.0028 20.4796 16.3871 20.5174 16.7364 20.6621C17.0856 20.8068 17.3841 21.0517 17.5942 21.3661C17.8042 21.6804 17.9163 22.0499 17.9163 22.4279C17.9163 22.9349 17.7149 23.421 17.3565 23.7795C16.998 24.1379 16.5118 24.3393 16.0049 24.3393Z" fill="#FF5656"/>
        </svg>
      )
    case 'info':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M16.0002 2.66406C23.3657 2.66406 29.3365 8.63488 29.3365 16.0004C29.3365 23.3646 23.3657 29.3341 16.0002 29.3341C8.63465 29.3354 2.6665 23.3646 2.6665 16.0004C2.66517 8.63488 8.63465 2.66406 16.0002 2.66406ZM15.9948 13.6643C15.6681 13.6648 15.3528 13.7852 15.109 14.0027C14.8652 14.2203 14.7097 14.5198 14.6721 14.8444L14.6628 14.999L14.6681 22.3352L14.6761 22.4899C14.7134 22.8151 14.869 23.1153 15.1133 23.3332C15.3576 23.5511 15.6735 23.6715 16.0008 23.6715C16.3282 23.6715 16.6441 23.5511 16.8884 23.3332C17.1327 23.1153 17.2883 22.8151 17.3255 22.4899L17.3335 22.3339L17.3282 14.9977L17.3189 14.8417C17.2803 14.5174 17.1241 14.2185 16.8798 14.0017C16.6355 13.7849 16.3201 13.6653 15.9935 13.6657L15.9948 13.6643ZM16.0015 8.66555C15.5589 8.66555 15.1345 8.84136 14.8215 9.1543C14.5086 9.46724 14.3328 9.89169 14.3328 10.3343C14.3328 10.7768 14.5086 11.2013 14.8215 11.5142C15.1345 11.8272 15.5589 12.003 16.0015 12.003C16.2206 12.0029 16.4374 11.9596 16.6398 11.8757C16.8421 11.7918 17.026 11.6689 17.1808 11.5139C17.3356 11.359 17.4584 11.175 17.5422 10.9726C17.6259 10.7702 17.669 10.5533 17.6689 10.3343C17.6688 10.1152 17.6256 9.89832 17.5417 9.69597C17.4577 9.49363 17.3348 9.3098 17.1798 9.15497C17.0249 9.00014 16.841 8.87734 16.6386 8.7936C16.4361 8.70985 16.2192 8.66679 16.0002 8.66688L16.0015 8.66555Z" fill="#71FFF6"/>
        </svg>
      )    
    case 'success':
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
          <path d="M15.9998 2.66699C8.63984 2.66699 2.6665 8.64032 2.6665 16.0003C2.6665 23.3603 8.63984 29.3337 15.9998 29.3337C23.3598 29.3337 29.3332 23.3603 29.3332 16.0003C29.3332 8.64032 23.3598 2.66699 15.9998 2.66699ZM14.4443 21.5559C13.8306 22.1695 12.8357 22.1695 12.2221 21.5559L7.60716 16.941C7.08765 16.4215 7.08765 15.5792 7.60716 15.0597C8.12616 14.5407 8.96744 14.5401 9.48716 15.0584L13.1052 18.6664C13.2312 18.7919 13.435 18.7918 13.5608 18.666L22.5065 9.72033C23.0288 9.19804 23.876 9.19954 24.3965 9.72367C24.9143 10.2452 24.9129 11.0873 24.3932 11.607L14.4443 21.5559Z" fill="#29FF89"/>
        </svg>
      )
  }
}

const CustomAlertTemplate = ({ message, options, style, close }) => (
  <div style={{ ...alertStyle, ...style }}>
    <div style={contentWrapperStyle}>
      <div style={iconPlaceholderStyle}>
        <div style={{ width: 32, height: 32}} >
          {getIcon(options.type)}
        </div>
      </div>
      <div style={messageStyle} className="body-3">
        {message}
      </div>
      <div onClick={close} style={closeButtonStyle} >
        <svg fill='#8b8a8a' xmlns='http://www.w3.org/2000/svg' height='25' width='25' viewBox='0 0 48 48'>
          <path d='M38 12.83l-2.83-2.83-11.17 11.17-11.17-11.17-2.83 2.83 11.17 11.17-11.17 11.17 2.83 2.83 11.17-11.17 11.17 11.17 2.83-2.83-11.17-11.17z' />
          <path d='M0 0h48v48h-48z' fill='none' />
        </svg>
      </div>
    </div>
  </div>
)

export default CustomAlertTemplate