import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

function LoadingIcon() {
    return (
        <div className="flex-fill"> 
            <div className="d-flex flex-column align-items-center justify-content-center row-gap-2 px-3 py-3">
                <div>
                    <PulseLoader color="white" aria-label="Loading Spinner" size={12}  />
                </div>
                <div className="loading-placeholder-text">
                    Loading...
                </div>
            </div>
        </div>
    )
}

export default LoadingIcon;